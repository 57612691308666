<template>
    <div class="c-subscription-plan">
        <div class="o-row o-row--nested c-subscription-plan__section">
            <div
                :class="[
                    'o-extra-small--12',
                    'o-medium--8',
                    'o-medium--offset-2',
                    'o-large--6',
                    'o-large--offset-3',
                ]"
            >
                <label
                    v-if="!!content.label"
                    class="c-subscription-plan__label"
                >
                    {{ content.label }}
                </label>
                <h3
                    v-if="!!content.headline"
                    class="c-subscription-plan__heading"
                >
                    {{ content.headline }}
                </h3>
                <p v-if="!!content.body" class="c-anonymous-home__section-body u-spacer--5">
                    {{ content.body }}
                </p>
            </div>
            <div
                :class="[
                    'o-extra-small--12',
                    'o-medium--6',
                    'o-medium--offset-3',
                    'o-large--4',
                    'o-large--offset-4',
                ]"
            >
                <div v-if="!!pillContent" class="c-subscription-plan__subscription-graphic-wrapper">
                    <div class="c-subscription-plan__subscription-graphic-section">
                        <p class="o-text--heading-1 u-spacer--0pt5">
                            {{ pillContent.leftNumber }}
                        </p>
                        <label>{{ pillContent.leftLabel }}</label>
                    </div>
                    <div class="c-subscription-plan__subscription-graphic-section">
                        <p class="o-text--heading-1 u-spacer--0pt5">
                            {{ pillContent.middleNumber }}
                        </p>
                        <label>{{ pillContent.middleLabel }}</label>
                    </div>
                    <div class="c-subscription-plan__subscription-graphic-section">
                        <p class="o-text--heading-1 u-spacer--0pt5">
                            {{ pillContent.rightNumber }}
                        </p>
                        <label>{{ pillContent.rightLabel }}</label>
                    </div>
                </div>
            </div>
            <div
                :class="[
                    'o-extra-small--12',
                    'o-medium--6',
                    'o-medium--offset-3',
                    'o-large--6',
                    'o-large--offset-3',
                ]"
            >
                <div class="c-subscription-plan__bullets-container u-spacer--5">
                    <ul class="c-subscription-plan__bullet-ul">
                        <li v-for="item of content.column1Bullets" :key="item" class="c-subscription-plan__bullet">
                            <BaseIcon
                                icon="global--checkmark-bordered"
                                color="primary"
                                size="24px"
                            />
                            <p class="c-subscription-plan__bullet-text">
                                {{ item }}
                            </p>
                        </li>
                    </ul>
                    <ul class="c-subscription-plan__bullet-ul">
                        <li v-for="item of content.column2Bullets" :key="item" class="c-subscription-plan__bullet">
                            <BaseIcon
                                icon="global--checkmark-bordered"
                                color="primary"
                                size="24px"
                            />
                            <p class="c-subscription-plan__bullet-text">
                                {{ item }}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <template v-if="showBottomCTAs">
            <BaseLink v-if="!!content.ctaTitle && !!content.ctaUrl" :to="content.ctaUrl" class="u-spacer--3">
                <BaseButton>
                    {{ content.ctaTitle }}
                </BaseButton>
            </BaseLink>
            <BaseLink v-if="!!content.linkUrl && !!content.linkTitle" :to="content.linkUrl" class="o-text--link">
                {{ content.linkTitle }}
            </BaseLink>
        </template>
    </div>
</template>

<script>
import BaseButton from '~coreModules/core/components/ui/buttons/BaseButton.vue';

export default {
    name: 'PlanSummaryModule',
    components: {
        BaseButton,
    },
    props: {
        content: {
            type: Object,
            required: true,
        },
        showBottomCTAs: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        pillContent() {
            return this.content.pill;
        },
    },
};
</script>

<style lang="scss">
    .c-subscription-plan {
        $this: &;

        text-align: center;

        &__section,
        &__subscription-graphic-wrapper,
        &__subscription-graphic {
            width: 100%;
        }

        &,
        &__subscription-graphic-section {
            flex-direction: column;
        }

        &,
        &__subscription-graphic-wrapper,
        &__subscription-graphic-section {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__label,
        &__heading {
            display: block;
            margin-bottom: $nu-spacer-1;
        }

        &__subscription-graphic-wrapper {
            margin: 0 0 $nu-spacer-5;
            flex-wrap: nowrap;
            height: 72px;
        }

        &__subscription-graphic-section {
            min-width: 100px;
            width: 33%;
            height: 100%;
            background-color: $nu-primary;
            color: $nu-white;
            flex: 0 1 auto;

            &:first-of-type {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            &:last-of-type {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            &:not(:last-of-type) {
                border-right: 1px solid $nu-white;
            }
        }

        &__bullets-container {
            text-align: left;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            max-width: 300px;

            @include breakpoint(large) {
                justify-content: unset;
                max-width: unset;
                flex-wrap: nowrap;
            }
        }

        &__bullet-ul {
            flex: 0 0 300px;

            @include breakpoint(large) {
                flex: 1;
                // in large breakpoints, have the width of the checkbox column take up 50% of container minus
                // half the grid row gap so the 2nd group aligns with the 7th column
                max-width: calc(50% - $nu-spacer-1pt5);
            }

            &:first-of-type {
                margin-bottom: $nu-spacer-3;

                @include breakpoint(large) {
                    margin: 0 $nu-spacer-3 0 0;
                }
            }
        }

        &__bullet-text {
            margin-left: $nu-spacer-1pt5;
            flex: 1;
        }

        &__bullet {
            display: flex;
            justify-content: flex-start;

            &:not(:last-of-type) {
                margin-bottom: $nu-spacer-3;
            }
        }
    }
</style>
