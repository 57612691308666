<template>
    <div
        v-if="shouldShowSocialGrid"
        class="o-row o-row--nested c-social-grid-module__column-gap"
        :style="style"
    >
        <div
            v-for="(result, index) in results"
            :key="`social-grid-module-${index}`"
            :class="columnClass"
        >
            <LazyLoad :vSlot="`gridItem-${index}`">
                <BaseLink :to="link" class="c-social-grid-module__link">
                    <BaseImage
                        :defaultURL="result"
                        :imgClass="'c-social-grid-module__img'"
                    />
                </BaseLink>
            </LazyLoad>
        </div>
    </div>
</template>

<script>
import {
    SOCIAL_GALLERY_DESKTOP_NUM_ROW_ITEMS,
    SOCIAL_GALLERY_MOBILE_NUM_ROW_ITEMS,
    SOCIAL_GALLERY_MAX_ITEMS,
} from '~coreModules/social/js/social-constants';

import BaseImage from '~coreModules/core/components/ui/BaseImage.vue';
import LazyLoad from '~coreModules/core/components/ui/LazyLoad.vue';

export default {
    name: 'SocialGridModule',
    components: { LazyLoad, BaseImage },
    props: {
        name: {
            type: String,
            required: true,
        },
        gridImages: {
            type: Array,
            required: true,
        },
        paddingBottom: {
            type: String,
            default: '0px',
        },
        link: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        isMounted: false,
    }),
    computed: {
        totalItems() {
            return this.gridImages.length;
        },
        maxItems() {
            const totalItemsOnDesktop = this.totalItems - (this.totalItems % SOCIAL_GALLERY_DESKTOP_NUM_ROW_ITEMS);
            const totalItemsOnMobile = this.totalItems - (this.totalItems % SOCIAL_GALLERY_MOBILE_NUM_ROW_ITEMS);

            return this.$mediaQueries.isLargish ? totalItemsOnDesktop : totalItemsOnMobile;
        },
        results() {
            if (this.maxItems > SOCIAL_GALLERY_MAX_ITEMS) {
                return this.gridImages.slice(0, SOCIAL_GALLERY_MAX_ITEMS);
            }
            return this.gridImages.slice(0, this.maxItems);
        },
        shouldShowSocialGrid() {
            if (this.isMounted) {
                return this.$mediaQueries.isLargish ?
                    this.maxItems >= SOCIAL_GALLERY_DESKTOP_NUM_ROW_ITEMS :
                    this.maxItems >= SOCIAL_GALLERY_MOBILE_NUM_ROW_ITEMS;
            }
            return false;
        },
        columnClass() {
            return this.$mediaQueries.isLargish ? 'o-extra-small--3' : 'o-extra-small--4';
        },
        style() {
            if (this.shouldShowSocialGrid) {
                return {
                    paddingBottom: this.paddingBottom,
                };
            }
            return {};
        },
    },
    mounted() {
        this.isMounted = true;
    },
};
</script>

<style lang="scss">
    .c-social-grid-module {
        &__column-gap {
            grid-column-gap: $nu-spacer-1 !important;

            @include breakpoint(medium) {
                grid-column-gap: $nu-spacer-2 !important;
            }
        }

        &__link {
            aspect-ratio: 1;
        }

        &__img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include breakpoint(medium) {
                margin-bottom:  $nu-spacer-1 !important;
            }
        }
    }
</style>
