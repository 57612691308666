import { get } from 'lodash-es';

/**
 * Returns a string to be used as the item's slot name.
 * Has a default value to be returned if the carouselItemUniqueKey is not found in the item
 * @param  {Object}  item carousel item data
 * @param  {String}  carouselItemUniqueKey key used to fetch a unique value for the given item
 * @param  {String}  index index of the item
 * @returns {String} slot name for the carousel item
 */
export function getCarouselSlotName(item, carouselItemUniqueKey, index) {
    return get(item, carouselItemUniqueKey, `carouselItem-${index}`);
}

export default {
    getCarouselSlotName,
};
