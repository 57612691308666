<template>
    <div class="c-ferris-wheel">
        <LazyLoad :observerCallback="lazyLoadCallback">
            <div class="o-row c-ferris-wheel__left-container">
                <div class="o-extra-small--12 o-medium--10 o-medium--offset-1 o-large--5">
                    <p
                        v-if="content.mainLabel"
                        class="o-text--label u-spacer--1"
                    >
                        {{ content.mainLabel }}
                    </p>
                    <h1
                        v-if="content.title"
                        class="u-spacer--1pt5 c-ferris-wheel__title"
                    >
                        {{ content.title }}
                    </h1>

                    <div class="c-ferris-wheel__bonus">
                        <span class="o-text--label">+10%</span>
                    </div>
                </div>
                <div class="o-extra-small--12 o-medium--10 o-medium--offset-1 o-large--4">
                    <p
                        v-if="content.body"
                        class="u-spacer--5"
                    >
                        {{ content.body }}
                    </p>
                </div>

                <div class="o-extra-small--12 o-medium--offset-1">
                    <p
                        v-if="content.brandsLabel"
                        class="o-text--label u-spacer--2"
                    >
                        {{ content.brandsLabel }}
                    </p>

                    <div
                        v-if="!!content.brands"
                        class="c-ferris-wheel__brands u-spacer--2"
                    >
                        <template v-for="{ brandImage, brandUrl, brandTitle } in content.brands" :key="brandUrl">
                            <BaseLink
                                :to="brandUrl"
                                class="c-ferris-wheel__brand"
                            >
                                <img
                                    :src="brandImage"
                                    :alt="brandTitle"
                                    class="c-ferris-wheel__logo"
                                    height="24"
                                >
                            </BaseLink>
                        </template>
                    </div>
                    <BaseLink
                        v-if="content.ctaUrl && content.ctaTitle"
                        :to="content.ctaUrl"
                        class="o-text--button c-button c-button--primary"
                    >
                        {{ content.ctaTitle }}
                    </BaseLink>
                </div>
            </div>

            <div v-if="!!content.ferrisWheel" class="c-ferris-wheel__circle-container">
                <div
                    v-for="index in 3"
                    :key="`ferris-wheel-text-${index}`"
                    :class="[
                        'c-ferris-wheel__text',
                        `c-ferris-wheel__text--${index}`,
                    ]"
                >
                    <p class="c-ferris-wheel__wheel-headline u-spacer--1">
                        {{ content.ferrisWheel[index - 1].headline }}
                    </p>
                    {{ content.ferrisWheel[index - 1].body }}
                </div>

                <div class="c-ferris-wheel__circle">
                    <div
                        v-for="index in 3"
                        :key="`ferris-wheel-illustration-${index}`"
                        :class="[
                            'c-ferris-wheel__illustration',
                            `c-ferris-wheel__illustration--${index}`
                        ]"
                    >
                        <img
                            :src="content.ferrisWheel[index - 1].image"
                            :alt="`Nuuly Cash illustration ${index}`"
                            height="240"
                            width="240"
                        >
                    </div>
                </div>
            </div>
        </LazyLoad>
    </div>
</template>

<script>
import { SCROLL_IN_ANIMATION_DELAY } from '~coreModules/core/js/constants';

import LazyLoad from '~coreModules/core/components/ui/LazyLoad.vue';

export default {
    name: 'FerrisWheelRowModule',
    components: {
        LazyLoad,
    },
    props: {
        content: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            animationStarted: false,
        };
    },
    methods: {
        lazyLoadCallback([{ isIntersecting }]) {
            if (process.env.VUE_ENV === 'client') {
                if (isIntersecting && !this.animationStarted) {
                    setTimeout(
                        () => {
                            this.$el.style.setProperty('--wheel-animation-state', 'running');
                        },
                        SCROLL_IN_ANIMATION_DELAY,
                    );
                }
            }
        },
    },
};

</script>

<style lang="scss">
.c-ferris-wheel {
    --wheel-animation-state: paused;

    $this: &;

    $circle-container: #{$this}__circle-container;
    $circle:           #{$this}__circle;
    $illustration:     #{$this}__illustration;
    $text:             #{$this}__text;

    $circle-diameter-xs: 624px;
    $circle-diameter-sm: 1200px;
    $circle-diameter-md: 1200px;
    $circle-diameter-lg: 900px;

    $illustration-height-xs: 126px;
    $illustration-height-lg: 156px;
    $illustration-height-xl: 220px;

    overflow: hidden;
    position: relative;
    background-color: $nu-white;
    padding: $nu-spacer-10 0;

    @include breakpoint(medium) {
        height: 860px;
    }

    @include breakpoint(large) {
        height: 800px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        padding: 0;
    }

    &__bonus,
    &__brand,
    &__circle-container {
        align-items: center;
        justify-content: center;
    }

    &__brand,
    &__circle-container {
        display: flex;
    }

    &__circle-container,
    &__circle {
        content: "";
    }

    &__circle,
    &__illustration,
    &__text {
        position: absolute;
        margin: auto;
    }

    &__left-container {
        // note: xs - m, the margin between this container and the circle is to be 80px.
        // the other GS2 is added to the top of the circle container to prevent the illustrations from clipping when the
        // circle transitions
        margin-bottom: $nu-spacer-8;

        @include breakpoint(large) {
            margin-bottom: 0;
        }
    }

    &__title {
        display: inline-block;
    }

    &__bonus {
        background-color: $nu-secondary;
        border-radius: 2px;
        display: inline-flex;
        position: relative;
        top: -16px;
        left: $nu-spacer-0pt5;
        height: 20px;
        padding: 0 $nu-spacer-1;
    }

    &__brands {
        @include clearfix;
    }

    &__logo {
        height: auto;
        width: 100%;
    }

    &__brand {
        float: left;
        clear: left;
        margin-bottom: $nu-spacer-3;
        margin-right: $nu-spacer-3;
        position: relative;
        overflow: visible;
        height: 24px;

        &:nth-of-type(even) {
            clear: right;
            margin-right: 0;
        }

        @for $index from 1 through 6 {
            &:nth-of-type(#{$index}) {
                @if $index == 1 {
                    width: 132px;
                } @else if $index == 2 {
                    width: 122px;
                } @else if $index == 3 {
                    width: 157px;
                } @else if $index == 4 {
                    width: 66px
                } @else if $index == 5 {
                    width: 67px;
                } @else if $index == 6 {
                    width: 109px;
                }
            }
        }

        @include breakpoint(medium) {
            // Create new stacking context to prevent hover blob from clipping above logos.
            // Used in conjunction with the ::before pseudoelement z-index
            transform: translate3d(0, 0, 0);

            &::before {
                position: absolute;
                margin: auto;
                content: ' ';
                background-image: url('../../../../core-lib/images/svgs/homepage__blob--hover.svg');
                background-position: center;
                background-size: cover;
                height: 28px;
                width: 96px;
                opacity: 0;
                transition: opacity 0.2s ease;
                z-index: -1;
            }

            &:hover,
            &[data-focus-visible-added] {
                &::before {
                    opacity: 1;
                }
            }
        }
    }

    &__circle-container {
        overflow: hidden;
        position: relative;

        height: 260px;

        // see note in &__left-container; the extra 16px here is treated as a part of bottom margin of &__left-container
        // it is also reflected in the "top: 16px" in &__circle
        padding-top: $nu-spacer-2;

        @include breakpoint(medium) {
            height: 400px;
        }

        @include breakpoint(large) {
            height: 100%;
            width: 50%;
            max-width: 720px; // === half of the max width of .o-row
            padding-top: 0;
            display:block;

            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
        }
    }

    &__circle {
        border: 1px solid $nu-gray--medium;
        border-radius: 50%;
        top: calc(#{$illustration-height-xs / 2} + 16px);

        @include dimensions($circle-diameter-xs);

        @include breakpoint(small) {
            @include dimensions($circle-diameter-sm);
        }

        @include breakpoint(medium) {
            @include dimensions($circle-diameter-md);
        }

        @include breakpoint(large) {
            @include dimensions($circle-diameter-lg);

            top: 0;
            bottom: 0;
            // this and the corresponding style in extra-large below prevent the left side/corners of illustrations from
            // clipping as they rotate (parent has overflow: hidden)
            left: calc(#{$illustration-height-lg / 2} + 16px);
        }

        @include breakpoint(extra-large) {
            left: calc(#{$illustration-height-xl / 2} + 16px);
        }
    }

    &__illustration {
        transition-duration: 0.5s;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        @include dimensions($illustration-height-xs);

        @include breakpoint(large) {
            @include dimensions($illustration-height-lg);
        }

        @include breakpoint(extra-large) {
            @include dimensions($illustration-height-xl);
        }

        @function radiusOf($diameter) {
            @return #{calc(-#{$diameter} / 2)};
        }

        $illustration-transform-data: (
            1: (
                x-lg: radiusOf($circle-diameter-lg),
                y-xs: radiusOf($circle-diameter-xs),
                y-sm: radiusOf($circle-diameter-sm),
            ),
            2: (
                x-xs: -270px,
                x-sm: -519px,
                x-lg: 225px,
                y-xs: 156px,
                y-sm: 300px,
                y-lg: 390px,
                rotation: 240deg,
            ),
            3: (
                x-xs: 270px,
                x-sm: 519px,
                x-lg: 225px,
                y-xs: 156px,
                y-sm: 300px,
                y-lg: -390px,
                rotation: 120deg
            ),
        );

        @mixin illustrationTransforms($data-map) {
            $deg:  map-get($data-map, rotation);
            $rotation: if($deg, rotate(#{$deg}), null);

            @each $bp in (xs, sm, lg) {
                $y: map-get($data-map, y-#{$bp});
                $x: map-get($data-map, x-#{$bp});

                $breakpoint: extra-small;
                @if      ($bp == lg) { $breakpoint: large }
                @else if ($bp == sm) { $breakpoint: small }

                @include breakpoint($breakpoint) {
                    transform:
                        if($x, translateX(#{$x}), null)
                        if($y, translateY(#{$y}), null)
                        #{$rotation};
                }
            }
        };

        @for $index from 1 through 3 {
            &--#{$index} {
                @include illustrationTransforms(map-get($illustration-transform-data, $index))
            }
        }
    }

    &__text {
        top: calc(#{$illustration-height-xs} + 60px);
        right: 0;
        left: 0;

        transition-duration: 1s;
        text-align: center;
        height: min-content;
        margin: auto $nu-spacer-2;

        @include breakpoint(large) {
            top: 0;
            bottom: 0;
            left: 220px;
            right: unset;

            text-align: left;
            max-width: 308px;
        }

        @include breakpoint(extra-large) {
            left: 308px;
        }
    }

    &__wheel-headline {
        @include heading-4;

        @include breakpoint(large) {
            @include heading-2;
        }
    }

    /*****************************
     *** animation definitions ***
     *****************************/

    @function twelfth($num) {
        @return 100% / 12 * $num;
    }

    @mixin child-visible($child) {
        $one-visible:   $child == 1;
        $two-visible:   $child == 2;
        $three-visible: $child == 3;

        --child-visibility-1: $one-visible;
        --child-visibility-2: $two-visible;
        --child-visibility-3: $three-visible;
    }

    #{$circle},
    #{$text},
    #{$illustration} {
        animation-duration: 12s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-play-state: var(--wheel-animation-state);
        will-change: animation-play-state, --wheel-animation-state;
    }

    #{$circle} {
        animation-name: rotate;

        @keyframes rotate {
            $twelfth: 100% / 12;

            0% {
                transform: rotate(0deg);
                @include child-visible(1);
            }
            #{twelfth(2)} {
                transform: rotate(0deg);
                @include child-visible(1);
            }
            #{twelfth(4)} {
                transform: rotate(120deg);
                @include child-visible(2);
            }
            #{twelfth(6)} {
                transform: rotate(120deg);
                @include child-visible(2);
            }
            #{twelfth(8)} {
                transform: rotate(240deg);
                @include child-visible(3);

            }
            #{twelfth(10)} {
                transform: rotate(240deg);
                @include child-visible(3);

            }
            100% {
                transform: rotate(360deg);
                @include child-visible(1);
            }
        }
    }

    @for $index from 1 through 3 {
        $animation-illustration: illustration-#{$index};
        #{$illustration}--#{$index} {
            animation-name: $animation-illustration;

            @keyframes #{$animation-illustration} {
                $twelfth: 100% / 12;

                @if $index == 1 {
                    0% {
                        opacity: 1;
                    }
                    #{$twelfth * 2} {
                        opacity: 1;
                    }
                    #{$twelfth * 3} {
                        opacity: 0;
                    }
                    #{$twelfth * 11} {
                        opacity: 0;
                    }

                    100% {
                        opacity: 1;
                    }
                } @else if ($index == 2) {
                    0% {
                        opacity: 0;
                    }
                    #{$twelfth * 3} {
                        opacity: 0;
                    }
                    #{$twelfth * 4} {
                        opacity: 1;
                    }
                    #{$twelfth * 6} {
                        opacity: 1;
                    }
                    #{$twelfth * 7} {
                        opacity: 0;
                    }
                    100% {
                        opacity: 0;
                    }
                } @else {
                    0% {
                        opacity: 0;
                    }
                    #{$twelfth * 7} {
                        opacity: 0;
                    }
                    #{$twelfth * 8} {
                        opacity: 1;
                    }
                    #{$twelfth * 10} {
                        opacity: 1;
                    }
                    #{$twelfth * 11} {
                        opacity: 0;
                    }
                    100% {
                        opacity: 0;
                    }
                }
            }
        }

        $animation-text: text-#{$index};
        #{$text}--#{$index} {
            animation-name: $animation-text;

            @keyframes #{$animation-text} {
                $twelfth: 100% / 12;

                @if $index == 1 {
                    0% {
                        opacity: 1;
                    }
                    #{$twelfth * 2} {
                        opacity: 1;
                    }
                    #{$twelfth * 3} {
                        opacity: 0;
                    }
                    #{$twelfth * 11} {
                        opacity: 0;
                    }

                    100% {
                        opacity: 1;
                    }
                } @else if ($index == 2) {
                    0% {
                        opacity: 0;
                    }
                    #{$twelfth * 3} {
                        opacity: 0;
                    }
                    #{$twelfth * 4} {
                        opacity: 1;
                    }
                    #{$twelfth * 6} {
                        opacity: 1;
                    }
                    #{$twelfth * 7} {
                        opacity: 0;
                    }
                    100% {
                        opacity: 0;
                    }
                } @else {
                    0% {
                        opacity: 0;
                    }
                    #{$twelfth * 7} {
                        opacity: 0;
                    }
                    #{$twelfth * 8} {
                        opacity: 1;
                    }
                    #{$twelfth * 10} {
                        opacity: 1;
                    }
                    #{$twelfth * 11} {
                        opacity: 0;
                    }
                    100% {
                        opacity: 0;
                    }
                }
            }
        }
    }
}
</style>
