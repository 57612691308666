<template>
    <BaseContentfulContentBlock
        :contentRows="contentRows"
        :contentTitle="contentTitle"
        :applyRowClass="applyRowClass"
        :pageGridWidth="pageGridWidth"
    >
        <template #default="{ module, numberOfModules, paddingBottom }">
            <ContentfulModule
                :module="module"
                :numberOfModules="numberOfModules"
                :paddingBottom="paddingBottom"
                :contentTitle="contentTitle"
            />
        </template>
    </BaseContentfulContentBlock>
</template>

<script>
import BaseContentfulContentBlock from '~coreModules/contentful/components/BaseContentfulContentBlock.vue';
import ContentfulModule from '~modules/contentful/components/ContentfulModule.vue';

export default {
    name: 'ContentfulContentBlock',
    components: {
        BaseContentfulContentBlock,
        ContentfulModule,
    },
    props: {
        contentRows: {
            type: Array,
            default: () => [],
            required: true,
        },
        contentTitle: {
            type: String,
            default: null,
        },
        applyRowClass: {
            type: Boolean,
            default: true,
        },
        pageGridWidth: {
            type: String,
            default: '',
        },
    },
    watch: {
        contentRows(newRows) {
            this.rows = newRows;
        },
    },
};
</script>
