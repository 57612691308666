<template>
    <div v-if="shouldShowSocialCarousel" :style="style">
        <Carousel
            :name="name"
            :carouselItems="results"
            :desktopSlideSize="4"
            :mobileSlideSize="1"
            :mobilePeekPercent="85"
            :lightButtons="true"
            :resetOnResize="true"
        >
            <template
                v-for="(result, index) in results"
                :key="`social-carousel-module-${index}`"
                #[`carouselItem-${index}`]
            >
                <LazyLoad v-slot="{ hasEnteredViewport }">
                    <BaseLink :to="getImageUrl(result)">
                        <BaseImage
                            v-if="hasEnteredViewport"
                            :defaultURL="result.squareImage"
                        />
                    </BaseLink>
                </LazyLoad>
            </template>
        </Carousel>
    </div>
</template>

<script>
import { get, head } from 'lodash-es';

import { getSocialGallery } from '~coreModules/social/js/social-api';

import BaseImage from '~coreModules/core/components/ui/BaseImage.vue';
import Carousel from '~coreModules/core/components/ui/Carousel.vue';
import LazyLoad from '~coreModules/core/components/ui/LazyLoad.vue';

export default {
    name: 'SocialCarouselModule',
    components: {
        BaseImage,
        Carousel,
        LazyLoad,
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        galleryId: {
            type: String,
            required: true,
        },
        maxItems: {
            type: Number,
            required: true,
        },
        paddingBottom: {
            type: String,
            default: '0px',
        },
    },
    data() {
        return {
            gallery: {},
        };
    },
    computed: {
        results() {
            return get(this.gallery, 'results', []);
        },
        shouldShowSocialCarousel() {
            return get(this.gallery, 'pagination.totalItems', 0) > 0;
        },
        style() {
            if (this.shouldShowSocialCarousel) {
                return {
                    paddingBottom: this.paddingBottom,
                };
            }
            return {};
        },
    },
    async created() {
        await getSocialGallery(this.$r15Svc, this.galleryId, this.maxItems)
            .then((res) => {
                this.gallery = res;
            })
            .catch((error) => {
                this.$logger.debugError(error);
            });
    },
    methods: {
        getImageUrl(result) {
            const products = get(result, 'products', []);
            return get(head(products), 'url', '');
        },
    },
};
</script>
