<template>
    <div class="c-five-up-grid o-row">
        <div class="c-five-up-grid__copy-container o-extra-small--12">
            <p v-if="!!content.label" class="u-spacer--1 o-text--label">
                {{ content.label }}
            </p>
            <h4 v-if="!!content.headline" class="u-spacer--1pt5 o-text--heading-1">
                {{ content.headline }}
            </h4>
            <p v-if="!!content.body" :class="{ 'u-spacer--2': showLink }">
                {{ content.body }}
            </p>
            <BaseLink v-if="showLink" :to="content.linkUrl" class="o-text--link">
                {{ content.linkText }}
            </BaseLink>
        </div>
        <div
            :class="[
                'o-extra-small--12',
                'o-medium--10 o-medium--offset-1',
                'o-large--6 o-large--offset-0',
            ]"
        >
            <FiveUpGridTile :image="shapedImages.firstImage" />
        </div>
        <div
            :class="[
                'o-extra-small--12',
                'o-medium--10 o-medium--offset-1',
                'o-large--6 o-large--offset-6',
            ]"
        >
            <ul class="c-five-up-grid__list o-row--nested">
                <li
                    v-for="(image, index) in shapedImages.images"
                    :key="`c-five-up-grid-item-${index}`"
                    class="c-five-up-grid__item"
                >
                    <FiveUpGridTile :image="image" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import FiveUpGridTile from '~coreModules/contentful/components/FiveUpGridTile.vue';

export default {
    name: 'FiveUpGridModule',
    components: {
        FiveUpGridTile,
    },
    props: {
        content: {
            type: Object,
            required: true,
        },
    },
    computed: {
        shapedImages() {
            const [firstImage, ...images] = this.content.images || {};
            return { firstImage, images };
        },
        showLink() {
            return !!this.content.linkUrl && !!this.content.linkText;
        },
    },
};

</script>

<style lang="scss">
    .c-five-up-grid {
        column-gap: $nu-spacer-0pt5;
        row-gap: $nu-spacer-0pt5;
        margin: $nu-spacer-10 auto;
        max-width: map-get($breakpoints, extra-large);

        @include breakpoint(medium) {
            margin: $nu-spacer-16 auto;
        }

        &__copy-container {
            margin-bottom: $nu-spacer-5;
            text-align: center;

            @include breakpoint(medium) {
                margin-bottom: $nu-spacer-8;
            }
        }

        &__list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: $nu-spacer-0pt5;
            row-gap: $nu-spacer-0pt5;
        }
    }
</style>
