<template>
    <div>
        <Carousel
            :name="name"
            :carouselItems="slides"
            :desktopSlideSize="largeBreakpointItemsPerSlide"
            :mediumSlideSize="1"
            :mobileSlideSize="1"
            :mobilePeek="false"
            :mobilePeekPercent="0"
            :noContainerMargin="!showDesktopMultiColumnLayout"
            :noSlidePadding="!showDesktopMultiColumnLayout"
            :hasIncreasedPageButtonSpacing="true"
            :displayCarouselPagination="!showDesktopMultiColumnLayout"
            :lightButtons="lightButtons"
            :resetOnResize="true"
            slideClass="c-carousel-module__slide"
        >
            <template
                v-for="(slide, index) in slides"
                #[`carouselItem-${index}`]
            >
                <template v-if="isMounted && slide.moduleType === CONTENTFUL_MODULE_TYPES.SHOPPABLE_IMAGE">
                    <component
                        :is="shoppableImageModule"
                        :key="slide.title"
                        :backgroundImage="slide.backgroundImage"
                        :buttons="slide.buttons"
                        :editorialText="slide.editorialText"
                        :choiceIds="slide.choices"
                        :ctaPosition="slide.ctaPosition"
                    />
                </template>
                <template v-else-if="isMounted">
                    <ImageModule
                        :key="slide.title"
                        :backgroundImage="slide.backgroundImage"
                        :buttons="slide.buttons"
                        :destination="slide.destination"
                        :editorialText="slide.editorialText"
                        :isFullWidth="true"
                        :numberOfModules="1"
                    />
                </template>
            </template>
        </Carousel>
    </div>
</template>

<script>

import { CONTENTFUL_MODULE_TYPES } from '~coreModules/contentful/js/contentful-constants';

import carouselModuleProps from '~coreModules/contentful/js/carouselModuleProps.js';

import ImageModule from '~coreModules/contentful/components/ImageModule.vue';
import Carousel from '~coreModules/core/components/ui/Carousel.vue';

export default {
    name: 'BaseCarouselModule',
    components: {
        Carousel,
        ImageModule,
    },
    mixins: [carouselModuleProps],
    data() {
        return {
            isMounted: false,
            CONTENTFUL_MODULE_TYPES,
        };
    },
    computed: {
        showDesktopMultiColumnLayout() {
            return this.isMounted && this.$mediaQueries.isLargish && this.largeBreakpointItemsPerSlide > 1;
        },
    },
    mounted() {
        this.isMounted = true;
    },
};
</script>

<style lang="scss">
    .c-carousel-module {
        &__slide {
            white-space: normal;
        }
    }
</style>
