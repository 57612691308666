import { ref, onMounted, getCurrentInstance } from 'vue';

/**
 * @description
 * checks if the component is mounted
 * @returns {Boolean} whether or not the the component is mounted
 */
export function useMounted() {
    const isMounted = ref(false);

    const instance = getCurrentInstance();
    if (instance) {
        onMounted(() => {
            isMounted.value = true;
        });
    }

    return isMounted;
}

export default {
    useMounted,
};
