<template>
    <div
        :class="{
            'c-contentful-block': true,
            'u-text--center': isContentCenter
        }"
    >
        <template v-for="(row, index) in rows">
            <!--
                empty style attr :style="{}" below fixes a very strange vue reactivity issue where
                getRowClasses was getting re-evaluated correctly on client render, but it was not re rendering
                with the correct values
            -->
            <div
                v-if="shouldRenderRow(row)"
                :key="`${row.title || ''}-${index}-${isMountedKey}`"
                :class="getRowClasses(row)"
                :style="{}"
            >
                <div v-if="row.leftOffset" :class="getRowLeftOffsetClasses(row)"></div>
                <div :class="getRowMainContentOffsetClasses(row)">
                    <div
                        :key="`${row.title || ''}-${index}-${isMountedKey}`"
                        :class="getRowClasses(row)"
                        class="o-row--nested"
                        :style="getRowStyle(row)"
                    >
                        <div
                            v-for="(module, moduleIndex) in row.modules"
                            :key="module.id"
                            :class="getColumns(row, moduleIndex)"
                            :style="getColumnStyle(row, moduleIndex)"
                            @click="onModuleClick($event, module)"
                        >
                            <LazyLoad
                                v-slot="{ hasEnteredViewport }"
                                bottomMargin="500px"
                            >
                                <AsyncContentFadeIn :hasMetMinimLoadTime="hasEnteredViewport">
                                    <slot
                                        :module="module"
                                        :paddingBottom="getPaddingBottom(row)"
                                        :numberOfModules="row.modules.length"
                                        :contentTitle="contentTitle"
                                    ></slot>
                                </AsyncContentFadeIn>
                            </LazyLoad>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { get } from 'lodash-es';
import { mapActions } from 'vuex';

import { getClickEventHref } from '~coreModules/core/js/utils';

import {
    CONDITIONAL_CONTENTFUL_MODULE_TYPES,
    CONTENTFUL_MODULE_TYPES,
    MAX_CONTENTFUL_ROW_COLUMN_OFFSET,
    EXCLUDED_CONTENTFUL_CONTENT_CLICKED_TYPES,
} from '~coreModules/contentful/js/contentful-constants';
import { CONTENTFUL_CONTENT_CLICKED } from '~coreModules/core/js/global-event-constants';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';

import AsyncContentFadeIn from '~coreModules/core/components/ui/AsyncContentFadeIn.vue';
import LazyLoad from '~coreModules/core/components/ui/LazyLoad.vue';

export default {
    name: 'BaseContentfulContentBlock',
    components: {
        AsyncContentFadeIn,
        LazyLoad,
    },
    props: {
        contentRows: {
            type: Array,
            default: () => [],
            required: true,
        },
        contentTitle: {
            type: String,
            default: null,
        },
        applyRowClass: {
            type: Boolean,
            default: true,
        },
        userIsEligibleToViewRow: {
            type: Function,
            default: () => true,
        },
        isContentCenter: {
            type: Boolean,
            default: false,
        },
        pageGridWidth: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            rows: this.contentRows,
            // used to solve ssr reactivity issue, inline methods would re-evaluate client side,
            // but not trigger re-render
            isMountedKey: 'not-mounted',
        };
    },
    watch: {
        contentRows(newRows) {
            this.rows = newRows;
        },
    },
    mounted() {
        this.isMountedKey = 'mounted';
    },
    methods: {
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        shouldRenderRow(row) {
            const rowIsValid = this.rowIsValid(row);
            const userIsEligibleToViewRow = this.userIsEligibleToViewRow(row);

            return rowIsValid && userIsEligibleToViewRow;
        },
        rowIsValid(row) {
            const totalColumnOffset = (row.leftOffset || 0) + (row.rightOffset || 0);
            const isValid = totalColumnOffset <= MAX_CONTENTFUL_ROW_COLUMN_OFFSET;

            if (!isValid) {
                this.$logger.debugError(`The contentful row "${row.title || 'UNTITLED ROW'}"
                    was authored incorrectly, the sum of the row's left and right column
                    offset cannot be greater than ${MAX_CONTENTFUL_ROW_COLUMN_OFFSET}`);
            }

            return isValid;
        },
        getRowLeftOffsetClasses(row) {
            const { leftOffset } = row;

            if (!leftOffset) {
                return '';
            }

            return `c-contentful-block__row-offset o-medium--${leftOffset}`;
        },
        getRowMainContentOffsetClasses(row) {
            const { leftOffset, rightOffset } = row;
            const totalColumnOffset = (leftOffset || 0) + (rightOffset || 0);
            let columnClass = 'o-extra-small--12';

            if (totalColumnOffset) {
                columnClass += ` o-medium--${12 - totalColumnOffset}`;
            }

            return columnClass;
        },
        // if row doesn't contain any conditionally rendered modules, allow padding bottom;
        allowPaddingBottom(row) {
            const modules = get(row, 'modules', []);

            return modules.map(e => CONDITIONAL_CONTENTFUL_MODULE_TYPES.includes(get(e, 'moduleType', '')))
                .includes(false);
        },
        getPaddingBottom(row) {
            if (this.allowPaddingBottom(row) || this.isMountedKey === 'not-mounted') {
                return '0px';
            }
            const {
                bottomPaddingLarge,
                bottomPaddingSmall,
            } = row;

            let paddingBottom = 0;

            if (bottomPaddingLarge && !this.$mediaQueries.isSmallish) {
                paddingBottom = bottomPaddingLarge;
            } else if (bottomPaddingSmall && this.$mediaQueries.isSmallish) {
                paddingBottom = bottomPaddingSmall;
            }

            return `${paddingBottom}px`;
        },
        getRowStyle(row) {
            if (this.isMountedKey === 'not-mounted') {
                return {
                    paddingBottom: '0px',
                    gridColumnGap: '0px',
                    gridRowGap: '0px',
                };
            }

            const {
                stackingGrid,
                noStacking,
                bottomPaddingLarge,
                bottomPaddingSmall,
                gutters,
            } = row;

            let paddingBottom = 0;
            let gridColumnGap = 0;
            let gridRowGap = 0;

            if (bottomPaddingLarge && !this.$mediaQueries.isSmallish && this.allowPaddingBottom(row)) {
                paddingBottom = bottomPaddingLarge;
            } else if (bottomPaddingSmall && this.$mediaQueries.isSmallish) {
                if (this.allowPaddingBottom(row)) {
                    paddingBottom = bottomPaddingSmall;
                }
                gridRowGap = bottomPaddingSmall;
            }

            // apply gutters if the following is true:
            // if noStacking is true or stackingGrid is 2x2
            // if noStacking is false and is not smallish
            if (gutters && (
                (noStacking || stackingGrid === '2x2') ||
                (!noStacking && !this.$mediaQueries.isSmallish))) {
                gridColumnGap = gutters;
            }

            return {
                paddingBottom: `${paddingBottom}px`,
                gridColumnGap: `${gridColumnGap}px`,
                gridRowGap: `${gridRowGap}px`,
            };
        },
        getRowClasses(row) {
            if (!this.applyRowClass) {
                return null;
            }

            let isFullBleed = !!row.fullBleedLarge;

            if (this.isMountedKey === 'mounted' && !this.$mediaQueries.isLargish) {
                isFullBleed = row.fullBleedSmall;
            }

            return ({
                'o-row': !isFullBleed,
                'o-row-full': isFullBleed,
                'o-row--nested': isFullBleed,
                'c-contentful-block__row': true,
                'c-contentful-block__row--small-only': row.showOnBreakpoint === 'Small',
                'c-contentful-block__row--large-only': row.showOnBreakpoint === 'Large',
                'c-contentful-block__row--accordion': !!row.accordion,
            });
        },
        getColumns(row, columnIndex) {
            const {
                columnSplits,
                modules,
                stackingGrid,
                noStacking,
            } = row;
            const numberOfColumns = modules ? modules.length : 0;

            // if row has no modules, don't return a class
            if (!numberOfColumns) {
                return '';
            }

            let mediumClasses = '';

            let extraSmallClasses = 'o-extra-small--12';

            // if row has column splits
            // if noStacking is true, apply split to all breakpoints
            // otherwise apply intended split to medium+ breakpoints
            if (columnSplits && columnSplits !== '50/50') {
                const splits = columnSplits.split('/');
                const columnProportion = parseInt(splits[columnIndex], 10) / 100;
                const columns = 12 * columnProportion;

                if (noStacking) {
                    extraSmallClasses = `o-extra-small--${Math.round(columns)}`;
                } else {
                    mediumClasses += `o-medium--${Math.round(columns)}`;
                }

            } else if (numberOfColumns > 1) {
                // if row is normal
                // and if noStacking is true, evenly split columns always
                // otherwise, evenly split columns on medium+ breakpoints
                if (noStacking) {
                    extraSmallClasses = `o-extra-small--${12 / numberOfColumns}`;
                } else {
                    mediumClasses += this.pageGridWidth === '12' ? 'o-medium--3' : `o-medium--${12 / numberOfColumns}`;
                }
            }

            if (stackingGrid && stackingGrid !== '1x4') {
                const gridSplits = stackingGrid.split('x');
                extraSmallClasses = `o-extra-small--${12 / gridSplits[0]}`;
            }

            let classes = `${extraSmallClasses} ${mediumClasses}`;

            if (get(modules[columnIndex], 'moduleType', '') === CONTENTFUL_MODULE_TYPES.EDITORIAL_TEXT) {
                classes += ' o-flex-vertical-center o-flex-horizontal-center';
            }

            if (get(modules[columnIndex], 'moduleType', '') === CONTENTFUL_MODULE_TYPES.CARD) {
                classes += ' c-contentful-block__column--card';
            }

            return classes;
        },
        getColumnStyle(row, columnIndex) {
            const { middleSpacing } = row;
            if (!middleSpacing || !this.$mediaQueries.isLargish) return {};

            const padding = parseInt(middleSpacing, 10) / 2;

            if (columnIndex === 0) {
                return {
                    'padding-right': `${padding}px`,
                };
            }

            return {
                'padding-left': `${padding}px`,
            };
        },
        onModuleClick(event, module) {
            const clickEventHref = getClickEventHref(event);

            if (!EXCLUDED_CONTENTFUL_CONTENT_CLICKED_TYPES.includes(module.moduleType)) {
                this.trackGlobalEvent({
                    type: CONTENTFUL_CONTENT_CLICKED,
                    data: {
                        destination: clickEventHref,
                        contentModuleId: module.title,
                        contentTitle: this.contentTitle,
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss">
    .c-contentful-block {
        &__row-offset {
            display: none;

            @include breakpoint(medium) {
                display: block;
            }
        }

        &__row {
            &--small-only {
                @include breakpoint(large) {
                    display: none;
                }
            }

            &--large-only {
                display: none;

                @include breakpoint(large) {
                    display: grid;
                }
            }

            &--accordion + &--accordion {
                .c-accordion {
                    border-top: none !important;
                }
            }
        }

        &__column {
            &--card {
                .c-lazyload, .c-async-content-fadein {
                    height: 100%;
                }
            }
        }
    }
</style>
