<template>
    <BaseMarkdown
        v-if="module.markdown"
        class="c-contentful-block__markdown"
        :source="module.markdown"
        :style="getMarkdownStyles(module)"
    />
    <HeroVideoModule
        v-else-if="module.moduleType === CONTENTFUL_MODULE_TYPES.HERO_VIDEO"
        :backgroundVideo="module.backgroundVideo"
        :backgroundImage="module.backgroundImage"
        :buttons="module.buttons"
        :destination="module.destination"
        :editorialText="module.editorialText"
        :isFullWidth="numberOfModules === 1"
        :numberOfModules="numberOfModules"
    />
    <ImageModule
        v-else-if="module.moduleType === CONTENTFUL_MODULE_TYPES.IMAGE"
        :backgroundImage="module.backgroundImage"
        :buttons="module.buttons"
        :destination="module.destination"
        :editorialText="module.editorialText"
        :isFullWidth="numberOfModules === 1"
        :numberOfModules="numberOfModules"
        :stretchImageToContainer="module.stretchImageToContainer"
    />
    <EditorialTextModule
        v-else-if="module.moduleType === CONTENTFUL_MODULE_TYPES.EDITORIAL_TEXT"
        :buttons="module.buttons"
        :editorialText="module.editorialText"
        :numberOfModules="numberOfModules"
    />
    <TableModule
        v-else-if="module.moduleType === CONTENTFUL_MODULE_TYPES.TABLE"
        :displayTitle="module.tableDisplayTitle"
        :shouldStackOnMobile="module.stackOnMobile"
        :tableData="module.tableJson"
    />
    <CardModule
        v-else-if="module.moduleType === CONTENTFUL_MODULE_TYPES.CARD"
        :heading="module.heading"
        :subheading="module.subheading"
        :illustration="module.illustration"
        :destination="module.destination"
        :cardLayout="module.cardLayout"
        :cardLinks="module.cardLinks"
    />
    <AccordionGroupModule
        v-else-if="module.moduleType === CONTENTFUL_MODULE_TYPES.ACCORDION_GROUP"
        :accordionGroupHeading="module.accordionGroupHeading"
        :accordions="module.accordions"
        :viewAllLink="module.viewAllLink"
    />
    <SocialCarouselModule
        v-else-if="module.moduleType === CONTENTFUL_MODULE_TYPES.SOCIAL_CAROUSEL"
        :name="module.moduleType"
        :galleryId="module.galleryId"
        :maxItems="module.maxItems"
        :paddingBottom="paddingBottom"
    />
    <!-- NU_TODO: update module as mentioned in https://urbnit.atlassian.net/browse/TYP-20269 -->
    <SocialGridModule
        v-else-if="module.moduleType === CONTENTFUL_MODULE_TYPES.SOCIAL_GRID"
        :name="module.moduleType"
        :galleryId="module.galleryId"
        :gridRows="module.gridRows"
        :paddingBottom="paddingBottom"
    />
    <VideoRowModule v-else-if="shouldRenderVideoRow(module)" :content="module.content" />
    <TwoUpRowModule
        v-else-if="shouldRenderTwoUpRowModule(module)"
        :content="module.content"
    />
    <PlanSummaryModule v-else-if="shouldRenderPlanSummary(module)" :content="module.content" />
    <BrandsRowModule v-else-if="shouldRenderBrandsRow(module)" :content="module.content" />
    <FerrisWheelRowModule v-else-if="shouldRenderFerrisWheelRowModule(module)" :content="module.content" />
    <FiveUpGridModule
        v-else-if="shouldRenderFiveUpGridModule(module)"
        :content="module.content"
    />
    <PrivacyOptOut v-else-if="shouldRenderPrivacyOptOut(module)" :content="module.content" />
    <AppRowModule v-else-if="shouldRenderAppRow(module)" :content="module.content" />
    <IllustratedListItem v-else-if="shouldRenderIllustratedListItem(module)" :content="module.content" />
    <PlanPillRow v-else-if="shouldRenderPlanPillRow(module)" :content="module.content" />
    <!-- NU_TODO: https://urbnit.atlassian.net/browse/TYP-17540 -->
    <!-- <BannerRowModule v-else-if="shouldRenderBannerRow(module)" :content="module.content" /> -->
</template>

<script>
import { validateCustomModule } from '~coreModules/contentful/js/contentful-utils';
import { formatHexColor } from '~coreModules/core/js/utils';

import {
    colorMap,
    CONTENTFUL_MODULE_TYPES,
    CONTENTFUL_CUSTOM_MODULE_TYPES,
} from '~coreModules/contentful/js/contentful-constants';

import AccordionGroupModule from '~coreModules/contentful/components/AccordionGroupModule.vue';
import BrandsRowModule from '~coreModules/contentful/components/BrandsRowModule.vue';
import BaseMarkdown from '~coreModules/core/components/ui/BaseMarkdown.vue';
import HeroVideoModule from '~coreModules/contentful/components/HeroVideoModule.vue';
import TwoUpRowModule from '~coreModules/contentful/components/TwoUpRowModule.vue';
import ImageModule from '~coreModules/contentful/components/ImageModule.vue';
import EditorialTextModule from '~coreModules/contentful/components/EditorialTextModule.vue';
import FiveUpGridModule from '~coreModules/contentful/components/FiveUpGridModule.vue';
import TableModule from '~coreModules/contentful/components/TableModule.vue';
import CardModule from '~coreModules/contentful/components/CardModule.vue';
import SocialCarouselModule from '~coreModules/contentful/components/SocialCarouselModule.vue';
import SocialGridModule from '~coreModules/contentful/components/SocialGridModule.vue';
import VideoRowModule from '~coreModules/contentful/components/VideoRowModule.vue';
import PlanSummaryModule from '~coreModules/contentful/components/PlanSummaryModule.vue';
import FerrisWheelRowModule from '~coreModules/contentful/components/FerrisWheelRowModule.vue';
import PrivacyOptOut from '~coreModules/compliance/components/PrivacyOptOut.vue';
import AppRowModule from '~coreModules/contentful/components/AppRowModule.vue';
import IllustratedListItem from '~coreModules/contentful/components/IllustratedListItem.vue';
import PlanPillRow from '~coreModules/contentful/components/PlanPillRow.vue';
// import BannerRowModule from '~coreModules/core/components/ui/contentful/BannerRowModule.vue';

export default {
    name: 'BaseContentfulModule',
    components: {
        AccordionGroupModule,
        BrandsRowModule,
        BaseMarkdown,
        HeroVideoModule,
        TwoUpRowModule,
        ImageModule,
        EditorialTextModule,
        FiveUpGridModule,
        TableModule,
        CardModule,
        SocialCarouselModule,
        SocialGridModule,
        VideoRowModule,
        FerrisWheelRowModule,
        PrivacyOptOut,
        PlanSummaryModule,
        AppRowModule,
        IllustratedListItem,
        PlanPillRow,
        // BannerRowModule,
    },
    props: {
        numberOfModules: {
            type: Number,
            required: true,
        },
        module: {
            type: Object,
            default: () => ({}),
            required: true,
        },
        paddingBottom: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            CONTENTFUL_MODULE_TYPES,
        };
    },
    methods: {
        validateCustomModule,
        getMarkdownStyles(module) {
            const { colorOverride, textAlignment } = module;

            const color = formatHexColor(colorOverride) || colorMap.get(colorOverride);

            return {
                ...(colorOverride && { color }),
                textAlign: textAlignment,
            };
        },
        shouldRenderFiveUpGridModule(module) {
            return validateCustomModule(CONTENTFUL_MODULE_TYPES.FIVE_UP_GRID_MODULE, module, this.$logger);
        },
        shouldRenderVideoRow(module) {
            return validateCustomModule(CONTENTFUL_CUSTOM_MODULE_TYPES.VIDEO_ROW, module, this.$logger);
        },
        shouldRenderPrivacyOptOut(module) {
            return validateCustomModule(CONTENTFUL_CUSTOM_MODULE_TYPES.PRIVACY_OPT_OUT, module, this.$logger);
        },
        shouldRenderTwoUpRowModule(module) {
            return validateCustomModule(CONTENTFUL_CUSTOM_MODULE_TYPES.TWO_UP_ROW, module, this.$logger);
        },
        shouldRenderFerrisWheelRowModule(module) {
            return validateCustomModule(CONTENTFUL_CUSTOM_MODULE_TYPES.FERRIS_WHEEL_ROW, module, this.$logger);
        },
        shouldRenderBannerRow(module) {
            return validateCustomModule(CONTENTFUL_CUSTOM_MODULE_TYPES.BANNER_ROW, module, this.$logger);
        },
        shouldRenderPlanSummary(module) {
            return validateCustomModule(CONTENTFUL_CUSTOM_MODULE_TYPES.PLAN_SUMMARY_ROW, module, this.$logger);
        },
        shouldRenderBrandsRow(module) {
            return validateCustomModule(CONTENTFUL_MODULE_TYPES.BRANDS_ROW, module, this.$logger);
        },
        shouldRenderAppRow(module) {
            return validateCustomModule(CONTENTFUL_CUSTOM_MODULE_TYPES.APP_ROW, module, this.$logger);
        },
        shouldRenderIllustratedListItem(module) {
            return validateCustomModule(CONTENTFUL_CUSTOM_MODULE_TYPES.ILLUSTRATED_LIST_ITEM, module, this.$logger);
        },
        shouldRenderPlanPillRow(module) {
            return validateCustomModule(CONTENTFUL_CUSTOM_MODULE_TYPES.PLAN_PILL_ROW, module, this.$logger);
        },
    },
};
</script>

<style lang="scss">

</style>
