<template>
    <div class="c-plan-pill-row">
        <div v-if="!!content" class="c-plan-pill-row__pill-graphic-wrapper">
            <div class="c-plan-pill-row__pill-graphic-section">
                <p class="o-text--heading-1 u-spacer--0pt5">
                    {{ content.leftNumber }}
                </p>
                <label>{{ content.leftLabel }}</label>
            </div>
            <div class="c-plan-pill-row__pill-graphic-section">
                <p class="o-text--heading-1 u-spacer--0pt5">
                    {{ content.middleNumber }}
                </p>
                <label>{{ content.middleLabel }}</label>
            </div>
            <div class="c-plan-pill-row__pill-graphic-section">
                <p class="o-text--heading-1 u-spacer--0pt5">
                    {{ content.rightNumber }}
                </p>
                <label>{{ content.rightLabel }}</label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PlanPillRow',
    props: {
        content: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss">
    .c-plan-pill-row {
        $this: &;

        text-align: center;

        &__pill-graphic-wrapper,
        &__pill-graphic {
            width: 100%;
        }

        &,
        &__pill-graphic-section {
            flex-direction: column;
        }

        &,
        &__pill-graphic-wrapper,
        &__pill-graphic-section {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__pill-graphic-wrapper {
            flex-wrap: nowrap;
            height: 72px;
        }

        &__pill-graphic-section {
            min-width: 115px;
            height: 100%;
            background-color: $nu-primary;
            color: $nu-white;
            flex: 0 1 auto;

            @include breakpoint(medium) {
                min-width: 120px
            }

            &:first-of-type {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            &:last-of-type {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            &:not(:last-of-type) {
                border-right: 1px solid $nu-white;
            }
        }

    }
</style>
