<template>
    <BaseLink :to="image.linkUrl || '#'" class="c-five-up-grid-tile">
        <BaseImage
            :defaultURL="image.src"
            :title="image.headline"
            imgClass="c-five-up-grid-tile__image"
        />
        <div class="c-five-up-grid-tile__image-caption">
            <h5 class="c-five-up-grid-tile__caption-headline">
                {{ image.headline }}
            </h5>
            <p class="c-five-up-grid-tile__caption-label">
                {{ image.label }}
            </p>
        </div>
    </BaseLink>
</template>

<script>
import BaseImage from '~coreModules/core/components/ui/BaseImage.vue';

export default {
    name: 'FiveUpGridTile',
    components: { BaseImage },
    props: {
        image: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss">
    .c-five-up-grid-tile {
        $this: &;

        position: relative;
        display: block;
        overflow: hidden;

        &:before {
            content: '';
            display: block;
            padding-top: 100%;
        }

        &:after {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            content: '';
            background-color: rgba($nu-gray, 0.2);
        }

        &__image {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.2s ease;

            #{$this}:hover & {
                transform: scale(1.1);
            }
        }

        &__image-caption {
            position: absolute;
            bottom: 0;
            left: 0;
            padding-left: $nu-spacer-2;
            color: $nu-white;
            z-index: 1;

            @include breakpoint(large) {
                padding-left: $nu-spacer-3;
            }
        }

        &__caption-headline {
            @include heading-5;

            margin-bottom: $nu-spacer-0pt5;

            @include breakpoint(medium) {
                @include heading-3;

                margin-bottom: $nu-spacer-1;
            }
        }

        &__caption-label {
            margin-bottom: $nu-spacer-2;

            @include breakpoint(medium) {
                margin-bottom: $nu-spacer-3;
            }
        }
    }
</style>
