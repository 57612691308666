<template>
    <div v-if="accordionIsValid" class="c-accordion-group">
        <div class="c-accordion-group__header">
            <EditorialTextModule
                :editorialText="accordionGroupHeading"
                :numberOfModules="shapedAccordions.length"
                class="c-accordion-group__text"
            />
            <BaseLink v-if="viewAllLink" :to="viewAllLink.linkPath" class="c-accordion-group__link">
                {{ viewAllLink.linkTitle }}
            </BaseLink>
        </div>
        <div
            v-for="(accordion, index) in shapedAccordions"
            :key="`${accordion.title}-${index}`"
            class="o-row o-row--nested"
        >
            <Accordion
                class="o-extra-small--12"
                headerClass="c-contentful-block__accordion-header"
                :isInitiallyOpen="accordion.accordionOpen"
                :moduleId="accordion.title"
                :analyticsTitle="accordion.title"
            >
                <template #header>
                    <h5>{{ accordion.accordionTitle }}</h5>
                </template>
                <template #body>
                    <BaseContentfulContentBlock
                        :contentRows="accordion.accordionContent"
                        :contentTitle="contentTitle"
                        :applyRowClass="false"
                    >
                        <template #default="{ module, numberOfModules, paddingBottom }">
                            <BaseContentfulModule
                                :module="module"
                                :numberOfModules="numberOfModules"
                                :paddingBottom="paddingBottom"
                            />
                        </template>
                    </BaseContentfulContentBlock>
                </template>
            </Accordion>
        </div>
    </div>
</template>

<script>

import { isEmpty } from 'lodash-es';
import { defineAsyncComponent } from 'vue';

import { normalizeContentfulRowModules } from '~coreModules/contentful/js/contentful-utils';

import Accordion from '~coreModules/core/components/ui/Accordion.vue';
import EditorialTextModule from '~coreModules/contentful/components/EditorialTextModule.vue';

export default {
    name: 'AccordionGroupModule',
    components: {
        Accordion,
        // must async import because of Circular References Between Components
        // https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components,
        BaseContentfulContentBlock: defineAsyncComponent(() => import(
            /* webpackChunkName: "BaseContentfulContentBlock" */
            '~coreModules/contentful/components/BaseContentfulContentBlock.vue')),
        BaseContentfulModule: defineAsyncComponent(() => import(
            /* webpackChunkName: "BaseContentfulModule" */
            '~coreModules/contentful/components/BaseContentfulModule.vue')),
        EditorialTextModule,
    },
    props: {
        accordionGroupHeading: {
            type: Object,
            required: true,
        },
        accordions: {
            type: Array,
            required: true,
        },
        viewAllLink: {
            type: Object,
            default: null,
        },
        contentTitle: {
            type: String,
            default: null,
        },
    },
    computed: {
        accordionIsValid() {
            return !isEmpty(this.accordions) && !isEmpty(this.accordionGroupHeading);
        },
        shapedAccordions() {
            if (isEmpty(this.accordions)) return null;

            return this.accordions.map(accordion => ({
                ...accordion,
                accordionContent: normalizeContentfulRowModules(accordion.accordionContent),
            }));
        },
    },
};
</script>

<style lang="scss">
    .c-accordion-group {
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: $nu-spacer-3;
            border-bottom: 1px solid $nu-white;
        }

        &__text {
            margin-right: $nu-spacer-2;
        }

        &__link {
            flex-shrink: 0;
        }
    }
</style>
