<template>
    <div class="c-table-module">
        <p v-if="displayTitle" class="o-text--heading-1 u-spacer--2">
            {{ displayTitle }}
        </p>
        <ZebraTable
            :columns="columns"
            :data="data"
            :shouldStackOnMobile="shouldStackOnMobile"
        />
    </div>
</template>

<script>
import { get } from 'lodash-es';
import ZebraTable from '~coreModules/core/components/ui/ZebraTable.vue';

export default {
    name: 'TableModule',
    components: {
        ZebraTable,
    },
    props: {
        displayTitle: {
            type: String,
            default: '',
        },
        shouldStackOnMobile: {
            type: Boolean,
            default: false,
        },
        tableData: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        columns() {
            const firstRow = get(this.tableData, 'rows[0].cells', []);
            const headers = firstRow.map(rowData => rowData[0] || '');
            return headers.map((header, index) => ({ displayName: header, key: `column${index}` }));
        },
        data() {
            const dataJson = this.tableData.rows.slice(1);
            return dataJson.map(rowData => rowData.cells.reduce((rowObject, currentCell, columnIndex) => {
                const key = `column${columnIndex}`;
                const value = currentCell.join(', ');
                return {
                    ...rowObject,
                    [key]: value,
                };
            }, {}));
        },
    },
};
</script>

<style lang="scss">
</style>
