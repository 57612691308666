<i18n>
[
    "global__success",
]
</i18n>

<template>
    <div class="c-success-check">
        <slot v-if="!showSuccessMessage" name="action"></slot>
        <template v-else>
            <BaseIcon
                icon="global--checkmark"
                :size="size"
                :color="color"
                transitionDuration="0"
                class="c-success-check__icon"
            />
            <span
                :class="{
                    'u-text--white': light,
                    'u-text--color-primary': !light,
                    'o-text--caption': true,
                }"
            >
                {{ appendedText }}
            </span>
        </template>
    </div>
</template>

<script>
import { NOTIFICATION_DISMISS_DELAY } from '~coreModules/core/js/constants';

export default {
    name: 'SuccessCheck',
    props: {
        text: {
            type: String,
            default: '',
        },
        light: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: '12px',
            validator(size) {
                return ['12px', '16px', '24px'].includes(size);
            },
        },
        duration: {
            type: Number,
            default: NOTIFICATION_DISMISS_DELAY.SHORT,
            validator: duration => Object.values(NOTIFICATION_DISMISS_DELAY).includes(duration),
        },
    },
    data() {
        return {
            showSuccessMessage: false,
            appendedText: this.text ? this.text : this.$t('global__success'),
            color: this.light ? 'white' : 'primary',
        };
    },
    methods: {
        changeSuccessMessageStatus() {
            this.showSuccessMessage = true;
            setTimeout(() => {
                this.showSuccessMessage = false;
            }, this.duration);
        },
    },
};
</script>

<style lang="scss">
    .c-success-check {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &__icon {
            margin-right: $nu-spacer-1;
        }
    }
</style>
