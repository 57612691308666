<template>
    <div class="c-two-up-row-module o-row-full">
        <div
            v-if="!!content.leftImageUrl"
            class="c-two-up-row-module__left-image o-extra-small--12 o-small--10 o-small--offset-1 o-large--5"
        >
            <img alt="" :src="content.leftImageUrl">
        </div>
        <div
            :class="[
                'c-two-up-row-module__centered-content',
                'o-extra-small--12',
                'o-small--10',
                'o-small--offset-1',
                'o-large--4',
                'o-large--offset-7',
            ]"
        >
            <img
                v-if="!!content.rightImageUrl"
                :src="content.rightImageUrl"
                alt=""
                class="c-two-up-row-module__right-image"
            >
            <p v-if="!!content.label" class="o-text--label u-spacer--1">
                {{ content.label }}
            </p>
            <h4 v-if="!!content.headline" class="c-two-up-row-module__heading u-spacer--1pt5">
                {{ content.headline }}
            </h4>
            <p v-if="!!content.body" class="u-spacer--4">
                {{ content.body }}
            </p>
            <BaseLink v-if="!!content.ctaTitle && !!content.ctaUrl" :to="content.ctaUrl">
                <BaseButton>
                    {{ content.ctaTitle }}
                </BaseButton>
            </BaseLink>
        </div>
    </div>
</template>

<script>
import BaseButton from '~coreModules/core/components/ui/buttons/BaseButton.vue';

export default {
    name: 'TwoUpRowModule',
    components: {
        BaseButton,
    },
    props: {
        content: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss">
    .c-two-up-row-module {
        max-width: map-get($breakpoints, extra-large);
        margin: 0 auto;
        padding-bottom: $nu-spacer-8;

        @include breakpoint (large) {
            padding-bottom: $nu-spacer-12;
        }

        &__left-image {
            margin-bottom: $nu-spacer-3;

            @include breakpoint(large) {
                margin-bottom: 0;
            }
        }

        &__heading {
            @include heading-3;

            @include breakpoint(medium) {
                @include heading-1;
            }
        }

        &__centered-content {
            margin: auto;
        }

        &__right-image {
            display: none;
            margin-bottom: $nu-spacer-3;

            @include breakpoint(large) {
                display: block;
            }
        }
    }
</style>
