<template>
    <div class="c-privacy-opt-out o-row-full">
        <div class="o-extra-small--12 u-spacer--8">
            <h5>{{ userPrivacyFields.title }}</h5>
        </div>
        <div class="o-extra-small--12 o-flex-horizontal-center u-spacer--2">
            <LoadingButton :isLoading="isLoading" @click="handleButtonClick">
                {{ userPrivacyFields.cta }}
            </LoadingButton>
        </div>
        <div class="o-extra-small--12 o-flex-horizontal-center u-spacer--8">
            <BaseSupportingTextError
                v-if="errorText"
                :errorText="errorText"
                class="u-spacer--3"
            />
            <SuccessCheck
                v-else-if="userHasToggledCta"
                :key="successCheckKey"
                ref="successMessage"
                :text="userPrivacyFields.successMessage"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import {
    COMPLIANCE_MODULE_NAME,
    UPDATE_COMPLIANCE_TRACKING_PREFERENCE,
} from '~coreModules/compliance/js/compliance-store';

import BaseSupportingTextError from '~coreModules/core/components/ui/form-elements/BaseSupportingTextError.vue';
import LoadingButton from '~coreModules/core/components/ui/buttons/LoadingButton.vue';
import SuccessCheck from '~coreModules/core/components/ui/SuccessCheck.vue';

export default {
    name: 'PrivacyOptOut',
    components: {
        BaseSupportingTextError,
        LoadingButton,
        SuccessCheck,
    },
    props: {
        content: {
            // See contentful-utils.js -> validateCustomModule() for validation details
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            userHasToggledCta: false,
            successCheckKey: 0,
            isLoading: false,
            errorText: '',
        };
    },
    computed: {
        ...mapGetters([
            'loggedIn',
        ]),
        ...mapGetters(COMPLIANCE_MODULE_NAME, [
            'shouldTrack',
        ]),
        userPrivacyFields() {
            const {
                registeredOptedInTitle,
                registeredOptedInMessage,
                registeredOptedOutTitle,
                registeredOptedOutMessage,
                anonymousOptedInTitle,
                anonymousOptedInMessage,
                anonymousOptedOutTitle,
                anonymousOptedOutMessage,
                optInCta,
                optOutCta,
            } = this.content.privacyOptOut;

            const fields = { };

            if (this.loggedIn) {
                if (this.shouldTrack) {
                    fields.title = registeredOptedInTitle;
                    fields.successMessage = registeredOptedInMessage;
                    fields.cta = optOutCta;
                    fields.ctaParameter = false;
                } else {
                    fields.title = registeredOptedOutTitle;
                    fields.successMessage = registeredOptedOutMessage;
                    fields.cta = optInCta;
                    fields.ctaParameter = true;
                }

            } else if (this.shouldTrack) {
                fields.title = anonymousOptedInTitle;
                fields.successMessage = anonymousOptedInMessage;
                fields.cta = optOutCta;
                fields.ctaParameter = false;
            } else {
                fields.title = anonymousOptedOutTitle;
                fields.successMessage = anonymousOptedOutMessage;
                fields.cta = optInCta;
                fields.ctaParameter = true;
            }

            return fields;
        },
    },
    methods: {
        ...mapActions(COMPLIANCE_MODULE_NAME, {
            updateTrackingPreference: UPDATE_COMPLIANCE_TRACKING_PREFERENCE,
        }),
        handleButtonClick() {
            this.userHasToggledCta = true;
            this.isLoading = true;

            const trackingPreference = this.userPrivacyFields.ctaParameter;

            this.updateTrackingPreference({ trackingPreference })
                .then(() => {
                    // success check component doesn't update its message when computed object changes; force re-render
                    this.successCheckKey++;
                    this.errorText = '';

                    this.$nextTick(() => {
                        this.$refs.successMessage.changeSuccessMessageStatus();
                    });
                })
                .catch((err) => {
                    this.errorText = err;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>

<style lang="scss">
    .c-privacy-opt-out {
        margin-top: $nu-spacer-3;
    }
</style>
