<template>
    <EditorialImageText
        v-if="editorialText"
        :editorialText="editorialText"
        :numberOfModules="numberOfModules"
        :buttons="textButtons"
        :applyCenterClass="false"
        class="c-editorial-text-module"
    />
</template>

<script>
import EditorialImageText from '~coreModules/contentful/components/EditorialImageText.vue';

export default {
    name: 'EditorialTextModule',
    components: {
        EditorialImageText,
    },
    props: {
        buttons: {
            type: Object,
            default: null,
        },
        editorialText: {
            type: Object,
            default: null,
        },
        numberOfModules: {
            type: Number,
            default: null,
        },
    },
    computed: {
        textButtons() {
            if (!this.editorialText || (this.buttons && this.buttons.ctaPosition === 'bottom')) {
                return null;
            }

            return this.buttons;
        },
    },
};
</script>

<style lang="scss">
    .c-editorial-text-module {
        width: 100%;
        padding: 0 !important;
    }
</style>
