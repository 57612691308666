<template>
    <div class="hero-video-module">
        <Component
            :is="setComponentTag"
            v-if="hasImage"
            v-bind="componentProps"
            @click="$emit('click', $event)"
        >
            <BaseVideo
                v-if="heroVideo && !videoHasError"
                ref="video"
                muted
                autoplay
                :src="heroVideo"
                class="hero-video-module__hero-media"
                @error="handleVideoError"
                @suspend="handleVideoSuspend"
            />
            <BaseImage
                v-else
                :sources="sources"
                :defaultURL="defaultImageUrl"
                :imgClass="imageClasses"
                :title="backgroundImage.description"
            />
            <slot></slot>
        </Component>
        <EditorialImageText
            v-if="editorialText"
            :editorialText="editorialText"
            :numberOfModules="numberOfModules"
            :buttons="textButtons"
        />
        <CTAButtons
            v-if="freeButtons"
            :buttons="freeButtons"
            :numberOfModules="numberOfModules"
            :isAbsoluteCentered="freeButtons.ctaPosition === 'center'"
        />
    </div>
</template>

<script>

import { get } from 'lodash-es';

import { DEFAULT_IMAGE_QUALITY } from '~coreModules/contentful/js/contentful-constants';

import BaseVideo from '~coreModules/core/components/ui/BaseVideo.vue';
import EditorialImageText from '~coreModules/contentful/components/EditorialImageText.vue';
import CTAButtons from '~coreModules/contentful/components/CTAButtons.vue';
import BaseImage from '~coreModules/core/components/ui/BaseImage.vue';

export default {
    name: 'HeroVideoModule',
    components: {
        BaseVideo,
        BaseImage,
        CTAButtons,
        EditorialImageText,
    },
    props: {
        backgroundImage: {
            type: Object,
            default: () => ({
                smallImage: {},
            }),
        },
        backgroundVideo: {
            type: Object,
            default: null,
        },
        buttons: {
            type: Object,
            default: null,
        },
        destination: {
            type: Object,
            default: null,
        },
        editorialText: {
            type: Object,
            default: null,
        },
        numberOfModules: {
            type: Number,
            default: null,
        },
    },
    emits: ['click'],
    data() {
        return {
            isMounted: false,
            videoHasError: false,
        };
    },
    computed: {
        hasImage() {
            return !!(get(this.backgroundImage, 'smallImage') || get(this.backgroundImage, 'largeImage'));
        },
        setComponentTag() {
            return this.destination ? 'BaseLink' : 'div';
        },
        componentProps() {
            if (!this.destination) {
                return {};
            }
            return {
                to: this.destination.linkPath,
                target: this.destination.openInNewWindow ? '_blank' : '_self',
            };
        },
        heroVideo() {
            if (this.isMounted) {
                const large = get(this.backgroundVideo, 'largeScreenUrl');
                const small = get(this.backgroundVideo, 'smallScreenUrl');

                try {
                    const url = this.$mediaQueries.isSmallish ? small : large;

                    // validate URLs; if not valid, only the hero image will be displayed
                    return new URL(url).href;
                } catch (_) {
                    if (!!large || !!small) {
                        // if both URLs aren't supplied, assume user is intentionally leaving blank to show only image
                        this.$logger.debugError('Invalid URL supplied for homepage anonymous hero videos');
                    }
                    return null;
                }
            }

            return null;
        },
        defaultImageUrl() {
            return this.getImageSrc('smallImage');
        },
        textButtons() {
            if (!this.editorialText || (this.buttons && this.buttons.ctaPosition === 'bottom')) {
                return null;
            }

            return this.buttons;
        },
        freeButtons() {
            if (this.editorialText && this.buttons && this.buttons.ctaPosition !== 'bottom') {
                return null;
            }

            return this.buttons;
        },
        imageClasses() {
            const isEditorialTextNotCentered = !!this.editorialText && (this.editorialText.textPosition !== 'center');

            return ({
                'hero-video-module__img': true,
                'hero-video-module__img--with-spacer': isEditorialTextNotCentered,
            });
        },
        sources() {
            const sources = [{
                srcset: this.getImageSrc('largeImage', 'webp'),
                media: '(min-width: 768px)',
                type: 'image/webp',
                valid: true,
            }, {
                srcset: this.getImageSrc('largeImage', 'jpg', 'progressive'),
                media: '(min-width: 768px)',
                type: 'image/jpeg',
                valid: this.isContentTypeValid('largeImage', 'image/jpeg'),
            }, {
                srcset: this.getImageSrc('largeImage'),
                media: '(min-width: 768px)',
                type: null,
                valid: true,
            }, {
                srcset: this.getImageSrc('smallImage', 'webp'),
                media: null,
                type: 'image/webp',
                valid: true,
            }, {
                srcset: this.getImageSrc('smallImage', 'jpg', 'progressive'),
                media: null,
                type: 'image/jpeg',
                valid: this.isContentTypeValid('smallImage', 'image/jpeg'),
            }];

            return sources.filter(source => source.valid && source.srcset);
        },
    },
    mounted() {
        this.isMounted = true;
    },
    methods: {
        getImageSrc(imgType, format, flag) {
            const url = get(this.backgroundImage, `${imgType}.url`);
            const quality = get(this.backgroundImage, 'imageQuality', DEFAULT_IMAGE_QUALITY);
            const formatParam = format ? `&fm=${format}` : '';
            const flagParam = flag ? `&fl=${flag}` : '';

            if (url) {
                return url.indexOf('?') !== -1 ? url : `${url}?q=${quality}${formatParam}${flagParam}`;
            }

            return null;
        },
        isContentTypeValid(imgType, contentType) {
            const entryContentType = get(this.backgroundImage, `${imgType}.contentType`);
            return contentType === entryContentType;
        },
        handleVideoError() {
            this.videoHasError = true;

            this.$logger.debugError('Anonymous homepage hero video failed to load');
        },
        handleVideoSuspend() {
            const { videoNode } = this.$refs.video;

            if (videoNode) {
                videoNode.play().catch(() => {
                    this.videoHasError = true;

                    this.$logger.debugError('hero video failed to load,' +
                        'potentially due to client\'s battery saving mode');
                });
            }
        },
    },
};
</script>

<style lang="scss">
    .hero-video-module {
        position: relative;
        height: 75vh;
        min-height: 500px;
        width: 100%;

        > a {
            display: block;
        }

        &__img {
            width: 100%;
            display: block;

            @include breakpoint(medium) {
                object-fit: cover;
                position: absolute;
                height: 100%;
            }

            &--with-spacer {
                margin-bottom: $nu-spacer-2;
            }
        }

        &__hero-media {
            object-fit: cover;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }
</style>
