import { markRaw } from 'vue';

export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        slides: {
            type: Array,
            default: () => [],
        },
        lightButtons: {
            type: Boolean,
            default: true,
        },
        largeBreakpointItemsPerSlide: {
            type: Number,
            default: 1,
            validator(numberOfSlides) {
                return [1, 2, 3].includes(numberOfSlides);
            },
        },
        gutters: {
            type: Number,
            default: 4,
            validator(gutterValue) {
                return [4, 8, 12, 16].includes(gutterValue);
            },
        },
        // Function returning a dynamically imported component, or a static component object
        shoppableImageModule: {
            type: [Function, Object],
            default: markRaw({
                render() {
                    return null;
                },
            }),
        },
    },
};
