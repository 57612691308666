import runtimeConfig from '~config/config';

const { apiUrls } = runtimeConfig;

/**
 * Get social gallery
 * @param {String} galleryId - the galleryId
 * @param {String} pageSize - the number of items to retrieve
 */
export function getSocialGallery(r15Svc, galleryId, pageSize) {
    const options = {
        params: {
            pageSize,
        },
    };
    return r15Svc.get(`${apiUrls.socialService}/gallery/${galleryId}`, options);
}

export default getSocialGallery;
