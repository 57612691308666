<template>
    <div class="c-video-row-module">
        <div class="c-video-row-module__container o-row-full">
            <div
                v-tab-focus.onspace="() => launchFullVideo()"
                :class="[
                    'o-extra-small--12',
                    'o-large--7',
                    'o-extra-large--8',
                    'c-video-row-module__video-row-section',
                    'c-video-row-module__video-row-section--video-wrapper',
                ]"
            >
                <BaseVideo
                    ref="teaserVideo"
                    :src="$mediaQueries.isSmallish ? teaserVideoUrl.small : teaserVideoUrl.large"
                    muted
                    autoplay
                    @ended="teaserComplete()"
                />
                <VideoButton
                    :activeButtonAction="activeVideoButtonAction"
                    :faceoutButtonText="true"
                    class="c-video-row-module__launcher"
                />
                <BaseVideo
                    ref="fullVideo"
                    :src="$mediaQueries.isSmallish ? fullVideoUrl.small : fullVideoUrl.large"
                    minimizeBehavior="reset"
                    preload="metadata"
                    controls
                    class="c-video-row-module__fullscreen-video"
                />
            </div>
            <div class="o-extra-small--12 o-large--5 o-extra-large--4 c-video-row-module__video-row-section">
                <div class="c-video-row-module__video-copy-container">
                    <div class="u-spacer--0pt5">
                        <label v-if="content.label">
                            {{ content.label }}
                        </label>
                    </div>
                    <h3 v-if="content.headline" class="c-video-row-module__heading o-text--title-3 u-spacer--1">
                        {{ content.headline }}
                    </h3>
                    <p v-if="content.body" class="u-spacer--4">
                        {{ content.body }}
                    </p>
                    <BaseLink
                        v-if="content.ctaUrl && content.ctaTitle"
                        :to="content.ctaUrl"
                    >
                        <BaseButton>
                            {{ content.ctaTitle }}
                        </BaseButton>
                    </BaseLink>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { CONTENTFUL_CONTENT_CLICKED } from '~coreModules/core/js/global-event-constants';
import { VIDEO_BUTTON_ACTIONS } from '~coreModules/core/js/video-constants';

import BaseButton from '~coreModules/core/components/ui/buttons/BaseButton.vue';
import BaseVideo from '~coreModules/core/components/ui/BaseVideo.vue';
import VideoButton from '~coreModules/core/components/ui/buttons/VideoButton.vue';

export default {
    name: 'VideoRowModule',
    components: {
        BaseVideo,
        VideoButton,
        BaseButton,
    },
    props: {
        content: {
            // See contentful-utils.js -> validateCustomModule() for validation details
            type: Object,
            required: true,
        },
        contentTitle: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            teaserHasCompleted: false,
            fullVideoHasPlayed: false,
            fullVideoNode: null,
        };
    },
    computed: {
        activeVideoButtonAction() {
            return this.fullVideoHasPlayed ? VIDEO_BUTTON_ACTIONS.replay : VIDEO_BUTTON_ACTIONS.play;
        },
        teaserVideoUrl() {
            return this.content.teaserVideoUrl;
        },
        fullVideoUrl() {
            return this.content.videoUrl;
        },
    },

    async mounted() {
        await this.$nextTick();
        this.fullVideoNode = this.$refs.fullVideo?.$el;

        if (this.fullVideoNode) {
            // safari has a CSS transform-related bug where the site nav will always show on top of fullscreen
            // elements, regardless of styles. The code below moves the video node out of its transformed parent
            document.querySelector('#app').appendChild(this.fullVideoNode);
        }
    },
    unmounted() {
        // ensure the fullscreen video node is destroyed despite being a child of div#app
        this.fullVideoNode?.remove();
    },
    methods: {
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        teaserComplete() {
            this.teaserHasCompleted = true;

            // prevent teaser video from restarting on mobile when the fullscreen video is opened, then closed
            this.$refs.teaserVideo?.$el?.removeAttribute('autoplay');
        },
        launchFullVideo() {
            this.$refs.fullVideo?.requestFullscreen()
                .then(() => {
                    this.trackGlobalEvent({
                        type: CONTENTFUL_CONTENT_CLICKED,
                        data: {
                            contentModuleId: 'video-clicked',
                            contentTitle: this.contentTitle,
                        },
                    });
                })
                .then(() => {
                    setTimeout(() => { this.fullVideoHasPlayed = true; }, 500);
                    this.fullVideoNode?.play();
                })
                .catch(err => this.$logger.debugError(err));
        },
    },
};
</script>

<style lang="scss">
    .c-video-row-module {
        &__video-row-section {
            &:first-of-type {
                margin-bottom: $nu-spacer-3;
            }

            &:last-of-type {
                margin-bottom: $nu-spacer-8;
            }

            @include breakpoint(large) {
                &:first-of-type {
                    margin-bottom: $nu-spacer-8;
                }
            }

            &--video-wrapper {
                position: relative;
                cursor: pointer;
            }
        }

        &__video-copy-container {
            @include breakpoint(large) {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                height: 100%;
                padding: 0 $nu-spacer-4;
            }
        }

        &__launcher {
            user-select: none;
            position: absolute;
            left: $nu-spacer-2;
            bottom: $nu-spacer-2;
        }

        &__play-button {
            margin-right: $nu-spacer-1;
        }

        &__fullscreen-video {
            position: fixed;
            left: -999999px;
            top: -999999px;
        }

        &__heading {
            @include heading-3;

            @include breakpoint(large) {
                @include heading-1;
            }
        }
    }
</style>
