<template>
    <div class="c-illustrated-list-item o-flex-horizontal-center">
        <div v-if="content.illustration" class="c-illustrated-list-item__illustration">
            <Illustration
                :illustrationSrc="content.illustration"
                illustrationSize="48"
            />
        </div>
        <div class="c-illustrated-list-item__content">
            <h5 v-if="content.title" class="u-spacer--0pt5">
                {{ content.title }}
            </h5>
            <p v-if="content.subtitle" class="o-text--caption u-text--black">
                {{ content.subtitle }}
            </p>
        </div>
    </div>
</template>

<script>
import Illustration from '~coreModules/core/components/ui/Illustration.vue';

export default {
    name: 'IllustratedListItem',
    components: {
        Illustration,
    },
    props: {
        content: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss">
    .c-illustrated-list-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &__illustration {
            background-color: $nu-secondary;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            padding: 16px;
        }

        &__content {
            padding-left: $nu-spacer-2;
        }

    }
</style>
