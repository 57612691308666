<template>
    <table class="c-zebra-table">
        <thead
            v-if="showHeader"
            :class="{ 'c-zebra-table__header--hidden': shouldHideHeaderOnMobile || shouldStackOnMobile }"
        >
            <tr>
                <th
                    v-for="column in columns"
                    :key="column.key"
                    class="c-zebra-table__header o-text--label u-text--gray"
                >
                    {{ column.displayName }}
                </th>
            </tr>
        </thead>
        <tbody>
            <template v-if="rowComponent">
                <component
                    :is="rowComponent"
                    v-for="(row, rowIndex) in data"
                    :key="rowIndex"
                    v-bind="{...row.props, ...$attrs}"
                    class="c-zebra-table__row"
                    :class="{ 'c-zebra-table__row--white': whiteRows }"
                />
            </template>
            <tr
                v-for="(row, rowIndex) in data"
                v-else
                :key="rowIndex"
                class="c-zebra-table__row"
                :class="[
                    row.class,
                    { 'c-zebra-table__row--white': whiteRows }
                ]"
            >
                <td
                    v-for="column in columns"
                    :key="column.key"
                    class="c-zebra-table__data"
                    :class="{ 'c-zebra-table__data--stackable': shouldStackOnMobile }"
                >
                    <p v-if="shouldStackOnMobile" class="c-zebra-table__cell-text c-zebra-table__cell-text--mobile">
                        <label class="u-text--black">{{ column.displayName }}</label>
                        {{ row[column.key] }}
                    </p>
                    <p
                        :class="{ 'c-zebra-table__cell-text--desktop': shouldStackOnMobile }"
                        class="c-zebra-table__cell-text"
                    >
                        {{ row[column.key] }}
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'ZebraTable',
    props: {
        columns: {
            type: Array,
            required: true,
            default: () => [],
        },
        // Function returning a dynamically imported component, or a static
        // component object
        rowComponent: {
            type: [Function, Object],
            default: null,
        },
        data: {
            type: Array,
            required: true,
            default: () => [],
        },
        shouldStackOnMobile: {
            type: Boolean,
            default: false,
        },
        shouldHideHeaderOnMobile: {
            type: Boolean,
            default: false,
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
        whiteRows: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
    .c-zebra-table {
        $this: &;
        width: calc(100% - (2 * #{$nu-spacer-2}));
        margin: 0 $nu-spacer-2;

        @include breakpoint(medium) {
            margin: 0;
            width: 100%;
        }

        &__header {
            background-color: $nu-gray--light;
            height: 48px;
            vertical-align: middle;

            &--hidden {
                display: none;

                @include breakpoint(medium) {
                    display: table-header-group;
                }
            }
        }

        &__row {
            color: $nu-black;

            &:nth-child(n) {
                background-color: $nu-white;
            }

            &:nth-child(2n) {
                background-color: $nu-gray--light;
            }

            &--white {
                background-color: $nu-white;

                &:nth-child(2n) {
                    background-color: $nu-white;
                }
            }
        }

        &__data {
            height: 48px;
            padding: $nu-spacer-1pt5 $nu-spacer-2;
            text-align: center;

            &--stackable {
                display: block;
                height: auto;
                padding: 0 $nu-spacer-2;
                text-align: left;

                &:first-child {
                    padding-top: $nu-spacer-2;
                }

                &:last-child {
                    padding-bottom: $nu-spacer-2;
                }

                @include breakpoint(medium) {
                    display: table-cell;
                    height: $nu-spacer-7;
                    padding: $nu-spacer-1pt5 $nu-spacer-2;
                    text-align: center;
                }

                #{$this}__cell-text {
                    &--desktop {
                        display: none;

                        @include breakpoint(small) {
                            display: block;
                        }
                    }

                    &--mobile {
                        display: block;

                        @include breakpoint(small) {
                            display: none;
                        }
                    }
                }
            }
        }

        &__cell-text--mobile {
            font-size: 1.2rem;
        }
    }
</style>
